@use 'config/variables' as *;
@use 'styleguide/layout' as *;
@use 'styleguide/text' as *;
@use 'config/variables' as *;
@use 'config/mixins' as *;
@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations' as *;

a:hover {
  color: $primary-300;
}

.th-btn {
  font-weight: $th-semi-bold;
  touch-action: manipulation;
  user-select: none;
  background-image: none;
  border: $border-xs solid transparent;
  border-radius: $th-border-radius;
  padding: ($cdl-padding-default * 0.5) $cdl-padding-default;
  text-align: center;
  text-decoration: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  background-color: $primary-300;
  color: $neutral-800;
  min-height: 1rem;

  &.default {
    color: $neutral-300;
    background-color: transparent;
    border-color: $neutral-300;
  }

  &.active {
    background-color: $cdl-action-primary;
    color: $neutral-0;
  }

  &.disabled {
    background-color: $neutral-100;
    color: $neutral-900;
    cursor: not-allowed;
  }
}

.th-ecg-list {
  @include flexVertical();
  justify-content: center;
  flex: 1;

  .list-container {
    @include flexVertical();
    flex: 1;
  }

  .list {
    @include flexVertical();
    align-items: center;
    padding: $cdl-padding-default;
    max-height: $body-height;
    overflow-y: auto;
  }

  .list>.total {
    width: $cdl-ecg-card-width;
    font-weight: $th-semi-bold;
    color: $neutral-300;
    padding: $cdl-padding-default 0;

    &>span:not(:first-of-type) {
      color: $neutral-800;
    }
  }
}

.th-btn-link {
  font-weight: $font-weight-semi-bold;
  padding: ($cdl-padding-default * 0.5) $cdl-padding-default;
  text-align: center;
  border-radius: $th-border-radius;
  text-decoration: none;
  border: $border-xs solid $neutral-100;
  cursor: pointer;
  background-color: $neutral-25;
  color: $neutral-800;
  min-height: 1rem;

  &>a {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: $neutral-900;
    border-color: $neutral-900;

    a {
      color: $neutral-900;
    }
  }

  &.active {
    background-color: $cdl-action-primary;
    color: $neutral-0;

    a {
      color: $neutral-0;
    }
  }

  &.disabled {
    background-color: grey;
    cursor: none;
    color: $neutral-900;

    a {
      color: $neutral-900;
    }
  }
}

.th-leftbar {
  background-color: $neutral-800;
  color: $neutral-25;
  font-weight: $font-weight-semi-bold;
  width: $leftbar-size;
  min-width: $leftbar-size;
  border-right: $border-xs solid $neutral-900;
  padding-top: $cdl-padding-default;

  .th-menu {
    box-sizing: border-box;
    min-width: 100%;
    max-width: 100%;
    overflow-y: auto;

    &.divider {
      color: $neutral-300;
      font-weight: $font-weight-regular;
      padding: $padding-sm $padding-m $padding-sm $padding-xxs;
    }

    &.infinite {
      @include infiniteScrollHeight($header-height + 12.2rem);
    }
  }

  .th-menu-item {
    padding: $padding-ms $padding-m;
    border-bottom: $border-xxs solid $neutral-900;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    font-size: $font-s;

    &:hover {
      background-color: $neutral-700;
    }

    &.active {
      box-shadow: inset $border-s 0 0 0 $neutral-300;
      background-color: $neutral-700;
    }

    &.discrete {
      font-size: $font-s;
      color: $neutral-300;
      padding-top: $padding-s;
      padding-bottom: $padding-s;
      border-bottom: none;
    }

    .cdl-icon {
      margin-right: $margin-xs;
      vertical-align: baseline;
      font-size: $font-s;
    }
  }
}

// FORMS

.th-form {
  background-color: $neutral-700;
  color: $neutral-300;
  padding: $cdl-padding-default $padding-m;
  min-width: 24rem;
  width: 24rem;
  overflow-x: hidden;
}

input.th-input {
  border: $border-xs solid transparent;
  border-radius: $radius-s;
  width: 100%;
  padding: $padding-s;

  &.dark {
    background-color: $neutral-600;
    border-color: $neutral-900;
    color: $neutral-25;
  }
}

input.th-input-material {
  box-shadow: none;
  border: none;
  border-bottom-color: $neutral-100;
  transition: border 0.3s;
  min-width: 100%;
  height: 2.5rem;
}

.th-input-material:focus,
.th-input-material.focus {
  border-bottom-color: $primary-300;
}

.th-input-material.cdl-error,
.th-input-material.cdl-error:focus,
.th-input-material.cdl-error.focus {
  border-bottom: solid $border-xs $red-500;
}

.th-selectable {
  &:hover {
    cursor: pointer;
    color: $primary-300;
    text-decoration-color: $primary-300;
  }
}

.th-right {
  float: right;
}

.th-block {
  display: block;
}

.th-discrete {
  opacity: 0.7;
}

// Triage Colors
.th-border-emergency {
  border-left-color: $red-500 !important;
}

.th-border-significant {
  border-left-color: $yellow-500 !important;
}

.th-border-nonsignificant {
  border-left-color: $primary-300 !important;
}

.th-border-normal {
  border-left-color: $neutral-100 !important;
}

.th-text-emergency {
  color: $red-500;
}

.th-text-significant {
  color: $yellow-500;
}

.th-text-nonsignificant {
  color: $primary-300;
}

.th-text-normal {
  color: $neutral-300;
}

// navbar submenus
.th-dropdown {
  $triangle-size: 0.7rem;
  position: relative;
  display: flex;

  // Dropdown Content (Hidden by Default)

  &>.dropdown-content {
    display: none;
    position: absolute;
    top: 2.8rem;
    right: 5%;
    background-color: $neutral-0;
    min-width: 90%;
    box-shadow: $shadow-200;
    border: $border-xxs solid rgba($neutral-900, 0.2);
    z-index: 2;
    border-radius: $radius-m;
  }

  &>.dropdown-content:before {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    display: block;
    top: (-$triangle-size + $border-xxs);
    right: 1rem;
    bottom: auto;
    left: auto;
    border-width: 0 $triangle-size $triangle-size;
    border-color: $neutral-25 transparent;
    z-index: -1;
  }

  &>.dropdown-content:after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    display: block;
    top: (-$triangle-size);
    right: 1rem;
    bottom: auto;
    left: auto;
    border-width: 0 $triangle-size $triangle-size;
    border-color: rgba($neutral-900, 0.2) transparent;
    z-index: -2;
  }

  // Links inside the dropdown
  &>.dropdown-content a {
    @include main-text();
    padding: $padding-s $padding-sm;
    display: block;
  }

  &>.dropdown-content a:not(:last-of-type) {
    border-bottom: $border-xxs solid $neutral-100;
  }

  &>.dropdown-content a:first-of-type {
    border-top-left-radius: $radius-m;
    border-top-right-radius: $radius-m;
  }

  &>.dropdown-content a:last-of-type {
    border-bottom-left-radius: $radius-m;
    border-bottom-right-radius: $radius-m;
  }

  // Change color of dropdown links on hover
  &>.dropdown-content a:hover {
    box-shadow: inset $padding-xxs 0 0 0 $primary-500;
    background-color: $neutral-25;
  }
}

// Show the dropdown menu on hover
.th-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content.show {
  display: block;
}

.dropdown-content.hide {
  display: none;
}

// Change the background color of the dropdown button when the dropdown content is shown
.th-dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.th-title-l {
  font-size: $font-xl;
  color: $neutral-900;
  font-weight: $font-weight-semi-bold;
}

.th-title-m {
  font-size: $font-l;
  color: $neutral-900;
  font-weight: $font-weight-semi-bold;
}

.th-title-m-light {
  font-size: $font-l;
  color: $neutral-300;
  font-weight: $font-weight-semi-bold;
}

.th-title-s {
  font-size: $font-m;
  color: $neutral-900;
  font-weight: $font-weight-semi-bold;
}

.th-title-s-light {
  font-size: $font-m;
  color: $neutral-300;
  font-weight: $font-weight-semi-bold;
}

.th-text-m {
  font-size: $font-m;
  color: $neutral-300;
  font-weight: $font-weight-regular;
}

.th-text-s {
  font-size: $font-s;
  color: $neutral-300;
  font-weight: $font-weight-regular;
}

.th-text-xs {
  font-size: $font-xs;
  color: $neutral-300;
  font-weight: $font-weight-regular;
}

.th-padding-bottom {
  padding-bottom: $cdl-padding-default;
}

.th-padding-right {
  padding-right: $cdl-padding-default;

  &-small {
    padding-right: $cdl-padding-small;
  }
}

.th-padding-left {
  padding-left: $cdl-padding-default;

  &-small {
    padding-left: $cdl-padding-small;
  }
}

.th-margin {
  &-right {
    margin-right: $cdl-padding-default;
  }
}

.th-success {
  color: $green-300;
}

.th-error {
  color: $red-500;
}

// Style placeholders

*::-webkit-input-placeholder {
  color: $neutral-300;
}

*:-moz-placeholder {
  // FF 4-18
  color: $neutral-300;
}

*::-moz-placeholder {
  // FF 19+
  color: $neutral-300;
}

*:-ms-input-placeholder {
  // IE 10+
  color: $neutral-300;
}
