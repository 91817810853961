@use 'config/variables' as *;
@use 'styleguide/text' as *;
@use 'styleguide/layout' as *;
@use 'libs/heartbeat/src/lib/assets/scss/hb-foundations/colors' as *;
@use 'styleguide/core' as *;
@use 'config/variables' as *;
@use 'styleguide/icons' as *;

//  Define global styles for svg.
//  Seperate overrides exist for resting & rd-resting

svg .axis > .tick {
  -webkit-user-select: none; // Chrome/Safari
  -moz-user-select: none; // Firefox
  -ms-user-select: none; // IE10+

  // Rules below not implemented in browsers yet
  -o-user-select: none;
  user-select: none;
}

//  RESTING

// Ruler
.cdl-ruler-line {
  stroke: $neutral-700;
  stroke-dasharray: 6, 2;
  stroke-opacity: 0.4;
  stroke-width: 2;
}

// Wave colors
.cdl-wave-p {
  fill: $cdl-wave-P;
}

.cdl-wave-qrs {
  fill: $cdl-wave-QRS;
}

.cdl-wave-t {
  fill: $cdl-wave-T;
}

.cdl-wave-inv {
  fill: $cdl-wave-INV;
}

.cdl-wave-normal,
.cdl-wave-manual {
  opacity: $cdl-wave-opacity;

  &.cdl-wave-t {
    opacity: $cdl-wave-opacity-light;
  }
}

$ladder-size: 16px;
$ladder-icon-size: 8px;

// Laddergram colors
.cdl-ladder-icon {
  text-align: center;
  vertical-align: middle;
  line-height: $ladder-size;
  font-size: $ladder-icon-size !important;
  width: $ladder-size;
  height: $ladder-size;
  cursor: pointer;
}

// Give box outline when premature
.cdl-svg-time-bg {
  stroke: $neutral-25;
  fill: $neutral-25;
  pointer-events: all;
  cursor: pointer;
}

.cdl-svg-time-slider {
  fill: $neutral-100;
  opacity: 1;
}

.cdl-svg-premature-square {
  border: 1px solid $neutral-900;
}

.cdl-svg-premature-circle {
  border: $border-xxs solid $neutral-900;
  border-radius: 50%;
}

.cdl-dots-conducted {
  stroke: $neutral-900;
  stroke-width: 1px;
}

.cdl-dots-QRS {
  // We need color for a circle, and border for triangle
  color: $cdl-wave-QRS !important;
  border-bottom-color: $cdl-wave-QRS;
}

.cdl-dots-P {
  // We need color for a circle, and border for triangle
  color: $cdl-wave-P !important;
  border-bottom-color: $cdl-wave-P;
}

// SO: https://css-tricks.com/snippets/css/css-triangle/
// Color is determined by second class, ie. dots-P or dots-QRS
.cdl-triangle {
  display: inline-block;
  $triangle-size: $ladder-icon-size - 2;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border-left: calc(#{$triangle-size} - 1px) solid transparent;
  border-right: calc(#{$triangle-size} - 1px) solid transparent;
  border-bottom-width: $triangle-size;
  border-bottom-style: solid;
}

.cdl-square {
  font-size: 12px; // TODO: icon size
  @include icon(square);
}

.cdl-circle {
  font-size: 12px; // TODO: icon size
  @include icon(circle);
}

.cdl-bolt {
  font-size: 12px; // TODO: icon size
  @include icon(bolt);
}

// big icon for pacemaker
.cdl-xxl-icon {
  font-size: 16px !important; // TODO: icon size
}

// Grid colors
.cdl-svg-grid-square {
  fill: $yellow-500;
  fill-opacity: 0.5;
}

.cdl-svg-grid-line {
  stroke: $yellow-500;
  stroke-width: $cdl-grid-width;
  opacity: 0.8;
}

// Lead colors
.cdl-svg-lead {
  fill: none;
  stroke: $neutral-800;
  stroke-width: $cdl-lead-width;
}

.cdl-svg-lead-name {
  fill: $neutral-800;
  fill-opacity: 0.5;
  @include font-in-pixels(l);
}

.cdl-svg-lead-bg {
  stroke-width: 10;
  stroke: transparent;
  fill: none;
  cursor: pointer;
}

.cdl-path-selected {
  stroke: $red-500 !important;
}

.brush .selection {
  stroke: #777777; // Non matching color
  stroke-opacity: 0.3;
}

// Delineation beats For R&D
.cdl-svg-tag-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1200;
  border-radius: $border-m;

  background-color: $neutral-25;

  font-family: Arial, sans-serif;
  font-size: $font-m;
  border: solid $border-xs $neutral-25;
  box-shadow: 0.4rem 0.4rem 0.8rem -0.2rem rgba($neutral-900, 0.4);

  &.hide {
    display: none !important;
  }

  & div.main {
    display: flex;
    flex-direction: row;
  }

  & div.actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-top: solid $border-xxs $neutral-100;
  }

  & ul {
    display: flex;
    flex-direction: column;

    min-width: 7rem;
    cursor: default;
    margin: 0;
    padding: 0 $padding-xs;

    list-style-type: none;

    .waves-button {
      border: 0.1rem solid transparent;
      border-radius: $radius-m;
    }

    li,
    .waves-button {
      padding: $padding-xs;
      margin: $margin-xxs * 0.5;
      font-size: $font-s;

      &:hover,
      &.selected {
        background-color: $primary-500;
        color: $neutral-25;
        cursor: pointer;
        border-radius: $border-m;
      }
    }

    span {
      color: $primary-500;
      padding: $padding-xs 0;
      font-size: $font-s;
    }
  }

  & ul:not(:first-child) {
    border-left: solid $border-xxs $neutral-100;
  }
}

// HOLTER
.cdl-rr-text {
  @include font-in-pixels(m, thin);
  opacity: 1;
  text-anchor: middle;
}

.cdl-d3-tip {
  line-height: 1;
  padding: 10px;
  background: rgba($neutral-900, 0.8);
  color: #fff;
  border-radius: 2px;
  @include font-in-pixels(xs);
}

// Creates a small triangle extender for the tooltip
.cdl-d3-tip:after {
  box-sizing: border-box;
  display: inline;
  @include font-in-pixels(xs);
  width: 100%;
  line-height: 1;
  color: rgba($neutral-900, 0.8);
  content: '\u25BC';
  position: absolute;
  text-align: center;
}

// Style northward tooltips differently
.cdl-d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}

.cdl-overlay {
  fill: none;
  pointer-events: all;
}

.cdl-pointer {
  cursor: pointer;
}

.cdl-mover {
  cursor: move;
}

.cdl-smart-mouse {
  stroke-width: 1;
  stroke: #aa3939;
  opacity: 0.4;
  fill: none;
}

.cdl-smart-mouse-text {
  fill: #aa3939;
}

.cdl-axis-style text {
  @include font-in-pixels(xs);
}

.cdl-axis-style.cdl-light-line line {
  stroke: $neutral-500;
}

.highlight-wave {
  opacity: 0.9 !important;
}

.no-events {
  pointer-events: none;
}

.cdl-img {
  border: $border-xxs solid $neutral-100;
}
